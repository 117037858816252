import { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Dialog } from '../Dialog';
import { Form } from 'components/common/inputs/Form';
import { ButtonPrimary, ButtonSecondary, WarningButton } from 'components/common/inputs/Button';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { DateInput } from 'components/common/inputs/DateInput';
import { useForm } from 'react-hook-form';
import { ChevronDown } from 'components/icons/ChevronDown';
import { Body1Bold, Body4 } from 'styles/FontStyles';
import { BREAKPOINT_MD, BREAKPOINT_SM } from 'styles/Breakpoints';
import { formatDateForBackend, getCurrentDayDate } from 'utils/dateUtils';
import { Colors } from 'styles/Colors';
import { PassengerAbsencesBridge } from 'bridges/PassengerAbsencesBridge';
import { useAuthHelp } from 'state/AuthState';
import { PassengerAbsenceCastParams } from 'json-schemas/PassengerAbsence.CastParams';
import { PassengerAbsenceResponseItem } from 'json-schemas/PassengerAbsence.ResponseItem';
import { TextInput } from 'components/common/inputs/TextInput';

const DropDownWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-block-start: 2rem;

  ${BREAKPOINT_SM} {
    justify-content: space-between;
  }
`;

const BackButton = styled.button`
  ${Body4};
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  grid-area: 1 / 1;

  svg {
    font-size: 1.5rem;
    stroke-width: 2px;
    transform: rotate(90deg);
  }

  @media (hover: hover) {
    :hover {
      cursor: pointer;
    }
  }
`;

const DeleteAbsenceDialogHeadline = styled.div`
  display: grid;
  grid-template-columns: 4.5rem 1fr 4.5rem;

  h4 {
    ${Body1Bold};
    grid-area: 2 / 1 / 2 / -1;

    ${BREAKPOINT_MD} {
      grid-area: 1 / 1 / 1 / -1;
    }
  }
`;

const DeleteScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-block: auto;
`;

const FirstSlideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
`;

const FirstSlideOut = keyframes`
  to {
    transform: translateX(-100%);
  }
`;

const SecondSlideIn = keyframes`
  from {
    transform: translateX(100%);
  }
`;

const SecondSlideOut = keyframes`
  to {
    transform: translateX(100%);
  }
`;

const DialogContentWrapper = styled.div<{ progressStep: number | null }>`
  display: grid;
  overflow: hidden;
  overflow-y: auto;

  & > * {
    grid-area: 1 / -1;

    margin-inline: -2rem;
    padding-inline: 2rem;

    transition: visibility 150ms ease-out;

    &:first-child {
      animation: ${({ progressStep }) => (progressStep === 2 ? FirstSlideOut : progressStep === 1 ? FirstSlideIn : 'none')} 150ms ease-out;
      visibility: ${({ progressStep }) => (progressStep === null || progressStep === 1 ? 'visible' : 'hidden')};
    }

    &:last-child {
      animation: ${({ progressStep }) => (progressStep === 2 ? SecondSlideIn : SecondSlideOut)} 150ms ease-out;
      visibility: ${({ progressStep }) => (progressStep === 2 ? 'visible' : 'hidden')};
    }
  }
`;

const Headline = styled.h4`
  ${Body1Bold};
`;

const ErrorText = styled.p`
  margin: 0.5rem 0 0 0;
  color: ${Colors.signalRed800};
  font-weight: bold;
`;

interface FormProps {
  startDate: string;
  endDate?: string;
  comment: string;
}

interface PassengerAbsenceDialogProps {
  id: string;
  item?: PassengerAbsenceResponseItem;
  onSuccess?: () => void;
  onClosed: () => void;
}

export const PassengerAbsenceDialog: FC<PassengerAbsenceDialogProps> = (props) => {
  const { id, item, onSuccess, onClosed } = props;

  const authHelp = useAuthHelp();
  const passengerAbsencesBridge = new PassengerAbsencesBridge(authHelp);

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [progressStep, setProgressStep] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [formValid, setFormValid] = useState<boolean>(true);

  const initialStartDate = item?.start_date ? new Date(item.start_date) : getCurrentDayDate(new Date());
  const initialEndDate = item?.end_date ? new Date(item.end_date) : undefined;

  const form = useForm<FormProps>({
    defaultValues: {
      startDate: initialStartDate.toString(),
      endDate: initialEndDate?.toString() || '',
      comment: item?.comment || '',
    },
  });

  const currentStartDate = form.watch('startDate');
  const currentEndDate = form.watch('endDate');

  useEffect(() => {
    if (currentStartDate && currentEndDate && getCurrentDayDate(currentStartDate) > getCurrentDayDate(currentEndDate)) {
      form.setValue('endDate', currentStartDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStartDate]);

  useEffect(() => {
    setOpen(true);
  }, []);

  const toBridgeParams = (values: FormProps): PassengerAbsenceCastParams => {
    return {
      start_date: formatDateForBackend(new Date(values.startDate)),
      end_date: values.endDate ? formatDateForBackend(new Date(values.endDate)) : '' ,
      comment: values.comment,
    };
  };

  const submitHandler = async (values: FormProps) => {
    setLoading(true);
    try {
      if (item?.id) {
        await passengerAbsencesBridge.updateWithId(id, item.id, toBridgeParams(values));
      } else {
        await passengerAbsencesBridge.create(id, toBridgeParams(values));
      }
      onSuccess?.();
      setOpen(false);
    } catch (error: any) {
      setErrorMessage(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteHandler = async () => {
    if (item?.id) {
      try {
        await passengerAbsencesBridge.deleteOne(id, item.id);
        onSuccess?.();
        setOpen(false);
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onClosed={onClosed}
      headline={
        <DialogContentWrapper progressStep={progressStep}>
          <Headline>Abwesenheit eintragen</Headline>

          <DeleteAbsenceDialogHeadline onClick={() => setProgressStep(1)}>
            <BackButton>
              <ChevronDown />
              Zurück
            </BackButton>
            <h4>Abwesenheitsperiode löschen?</h4>
          </DeleteAbsenceDialogHeadline>
        </DialogContentWrapper>
      }
      text={
        <DialogContentWrapper progressStep={progressStep}>
          <Form form={form} onSubmit={(values: FormProps) => submitHandler(values)}>
            <DropDownWrapper>
              <DateInput
                label={'Von*'}
                name={'startDate'}
                endDate={(currentEndDate && getCurrentDayDate(currentEndDate)) || undefined}
                selectType={'start'}
                />
              <DateInput
                label={'Bis'}
                name={'endDate'}
                minDate={(currentStartDate && getCurrentDayDate(currentStartDate)) || undefined}
                startDate={(currentStartDate && getCurrentDayDate(currentStartDate)) || undefined}
                selectType={'end'}
                optional={true}
              />
            </DropDownWrapper>
            <div>
              <TextInput
                id={`comment`}
                label={'Grund'}
                name={'comment'}
                defaultValue={item?.comment}
                placeholder={'Grund für die Abwesenheit'}
                optional={true}
              />
            </div>

            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}

            <ButtonWrapper>
              {item?.id ? (
                <WarningButton type={'button'} disabled={loading} onClick={() => setProgressStep(2)}>
                  {loading ? <LoadingSpinner /> : 'Löschen'}
                </WarningButton>
              ) : (
                <ButtonSecondary type={'button'} onClick={() => setOpen(false)}>
                  Abbrechen
                </ButtonSecondary>
              )}
              <ButtonPrimary type={'submit'} disabled={loading || !formValid}>
                {loading ? <LoadingSpinner /> : item?.id ? 'Aktualisieren' : 'Speichern'}
              </ButtonPrimary>
            </ButtonWrapper>
          </Form>

          <DeleteScreenWrapper>
            <TextWrapper>
              <p>
                Mit Klick auf <b>Wirklich löschen</b> wird diese Abwesenheitsperiode unwiederbringlich aus dem System gelöscht.
              </p>
              <b>Möchten Sie diese Abwesenheitsperiode wirklich löschen?</b>
            </TextWrapper>
            <ButtonWrapper>
              <ButtonSecondary onClick={() => setProgressStep(1)}>Abbrechen</ButtonSecondary>
              <WarningButton onClick={deleteHandler}>Wirklich löschen</WarningButton>
            </ButtonWrapper>
          </DeleteScreenWrapper>
        </DialogContentWrapper>
      }
      buttons={null}
    />
  );
};
