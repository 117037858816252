import { FC, useEffect, useMemo } from 'react';
import { useAuthToken } from 'state/AuthState';
import { useNavigate } from 'hooks/useNavigate';

type Props = {
  path: string;
  queryParams?: any,
  styleKind: string,
  onClose?: () => void;
}

const iframeStyle = {
  overlay: {
    position: "fixed", zIndex: 15000, top: 0, left: 0, margin: 0, padding: 0, opacity: 1, visibility: "visible", background: "rgba(0,0,0,0)"
  },
  mainSection: {
    margin: '1rem 0 0 0',
    padding: 0,
    opacity: 1,
    visibility: 'visible',
    background: 'rgba(0,0,0,0)',
    border: 0,
    height: 'calc(100vh - 420px)',
  }
} as any;

export const LiveIframe: FC<Props> = (props) => {
  const authToken = useAuthToken();
  const navigate = useNavigate();
  const navigateReplace = useNavigate(true); // problem: FF detects this as a popup!

  const identity = useMemo(() => `${Math.random()}`, []);
  const callback = (evt: any) => {
    if (evt.data.sourceIdentity == identity) {
      if (evt.data.what == "dismiss") {
        if (props.onClose) {
          evt.preventDefault();
          props.onClose();
        }
      } else if (evt.data.what == "navigate") {
        if (evt.data.replace) {
          navigateReplace(evt.data.path);
        } else {
          navigate(evt.data.path);
        }
        evt.preventDefault();
      } else if (evt.data.what == "redirect") {
        window.location = evt.data.path;
      }
    }
  }

  useEffect(() => {
    window.addEventListener("message", callback);
    return () => { window.removeEventListener("message", callback); };
  });

  const url = new URL(`/live/${props.path}`, window.location.href);
  url.hash = "#" + identity;
  for (const property in props.queryParams) {
    url.searchParams.append(property, props.queryParams[property])
  }
  if (authToken) {
    url.searchParams.append("authToken", authToken);
  }

  return (
    <iframe src={url.href} style={iframeStyle[props.styleKind]}/>
  )
}

