import { useEffect, useState } from 'react';
import { EmployeeForm } from 'components/content/employees/EmployeeForm';
import { Breadcrumb } from 'components/common/elements/Breadcrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { EmployeesBridge } from 'bridges/EmployeesBridge'
import { ContentPageLayout } from 'components/common/atoms/Layout';

import { useAuthHelp } from 'state/AuthState';
import { EmployeeResponseItem } from 'json-schemas/Employee.ResponseItem';

export const EmployeeEdit = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const authHelp = useAuthHelp();
  const employeesBridge = new EmployeesBridge(authHelp);

  const [employee, setEmployee] = useState<EmployeeResponseItem | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);

      employeesBridge.getById(id)
        .then((data) => {
          setEmployee(data);
        })
        .catch(() => {
          navigate('/404');
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <ContentPageLayout>
      <Breadcrumb
        items={[
          {
            title: 'Mitarbeiter Übersicht',
            href: '/employees',
          },
        ]}
      />
      {loading ? <LoadingSpinner /> : <EmployeeForm employee={employee !== null ? (employee as any) : undefined} />}
    </ContentPageLayout>
  );
};
