export const getISODate = (date: Date | string) => {
  const useableDate = typeof date === 'string' ? new Date(date) : date;

  return useableDate.toLocaleDateString('sv-SE', {
    // 'sv-SE' locale uses YYYY-MM-DD format
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const getCurrentDayDate = (current?: Date | string) => {
  const date = current ? new Date(current) : new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

export const addMonths = (date: Date, amount = 1) => {
  // Create a new date object to avoid mutating the original date
  const newDate = new Date(date);

  // Store the original day of the month
  const originalDay = newDate.getDate();

  // Add one month
  newDate.setMonth(newDate.getMonth() + amount);

  // Check if the month has changed and if the new month has fewer days
  if (newDate.getDate() !== originalDay) {
    // Set the date to the last day of the previous month
    newDate.setDate(0);
  }

  return newDate;
}

export const formatDateForBackend = (dateWithTimezone: Date) => {
  const date = getCurrentDayDate(dateWithTimezone);

  return getISODate(date);
};


export const getYearAndMonth = (date: Date | string) => {
  const useableDate = typeof date === 'string' ? new Date(date) : date;

  const month = (useableDate.getMonth() + 1).toString().padStart(2, '0');
  const year = useableDate.getFullYear();

  return `${year}-${month}`;
}

export const isDateInMonthArray = (date: Date, monthArray: Date[]) => {
  return monthArray.some((monthStartDate) => {
    // Create a new date representing the start of the next month
    const nextMonth = new Date(monthStartDate);
    nextMonth.setMonth(nextMonth.getMonth() + 1);

    // Check if the date falls between the start of the current month and the start of the next month
    return date >= monthStartDate && date < nextMonth;
  });
}

export const getDayOfWeekDate = (day: number, current?: Date | string) => {
  const date = current ? new Date(current) : new Date();
  date.setDate(date.getDate() + (day - date.getDay()));
  return date;
};

export const getDayOfMonthDate = (day: number, current?: Date | string) => {
  const date = current ? new Date(current) : new Date();
  date.setDate(day);
  return date;
};

export const getFirstOfNextMonthDate = (current?: Date | string) => {
  const date = getDayOfMonthDate(1, current);
  date.setMonth(date.getMonth() + 1);
  return date;
};

export const isLastDayOfMonth = (current?: Date | string) => {
  const date = current ? new Date(current) : new Date();
  const nextDate = new Date(date);
  nextDate.setDate(nextDate.getDate() + 1);
  return date.getMonth() !== nextDate.getMonth();
};

export const getFirstDayOfMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getLastDayOfMonth = (date: Date) => {
  //  when you pass 0 as the day in the constructor, it automatically refers to the last day of the previous month. That’s why setting the day to 0 effectively subtracts one day from the first day of the next month, giving us the last day of the current month.
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const getBookingMonthEndDate = (current?: Date | string) => {
  const timestamp = getCurrentDayDate(current); // timestamp of booking completion
  return isLastDayOfMonth(timestamp) ? timestamp : getDayOfMonthDate(0, timestamp); // end of past month
};

export const getWeekOfYear = (current?: Date | string) => {
  const date = getCurrentDayDate(getDayOfWeekDate(4, current)); // compare with nearest thursday (ISO 8601)
  const yearStartDate = getCurrentDayDate(new Date(date.getFullYear(), 0, 1));
  const daysDiff = (date.getTime() - yearStartDate.getTime()) / (24 * 3600 * 1000);
  return Math.ceil((daysDiff + 1) / 7);
};

export const getFormattedValue = (value: (Date | null) | [Date | null, Date | null]) => {
  if (Array.isArray(value)) {
    const start = value[0]?.toLocaleDateString(undefined, { dateStyle: 'long' });
    const end = value[1]?.toLocaleDateString(undefined, { dateStyle: 'long' });

    if (value[0] && value[1]) {
      if (value[0].getFullYear() === value[1].getFullYear()) {
        if (value[0].getMonth() === value[1].getMonth()) {
          if (value[0].getDate() === value[1].getDate()) {
            return `Nur am ${start}`;
          }
          return [value[0]?.toLocaleDateString(undefined, { day: '2-digit' }), end].join('. - ');
        }
        return [value[0]?.toLocaleDateString(undefined, { day: '2-digit', month: 'long' }), end].join(' - ');
      }
      return [start, end].join(' - ');
    } else if (value[0]) {
      return `Ab ${start}`;
    } else if (value[1]) {
      return `Bis ${end}`;
    }
    return undefined;
  }

  return value?.toLocaleDateString(undefined, { dateStyle: 'long' });
};
