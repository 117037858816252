import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { TabStatusBarLayout } from 'components/common/elements/TabStatusBarLayout';
import { CustomerDetailsStatusCard } from 'components/common/elements/customer/CustomerDetailsStatusCard';
import { CustomerDetailsInformation } from 'components/common/elements/customer/CustomerDetailsInformation';
import { CustomerDetailsContacts } from 'components/common/elements/customer/CustomerDetailsContacts';
import { CustomerDetailsOrders } from 'components/common/elements/customer/CustomerDetailsOrders';
import { CustomerDetailsPassengers } from 'components/common/elements/customer/CustomerDetailsPassengers';
// import { CustomerDetailsExceptions } from 'components/common/elements/customer/CustomerDetailsExceptions';
import { ToursByDayForWeek } from 'components/common/elements/ToursByDayForWeek';
import { useCustomersAPI } from 'api/controllers/CustomersAPI';
import { ICustomer } from 'api/models/Customer';
import CustomerDetailsAccounting from 'components/common/elements/customer/CustomerDetailsAccounting';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;
`;

export const CustomerDetails: FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const customersAPI = useCustomersAPI();
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState<ICustomer | null>(null);

  const fetchCustomer = useCallback(() => {
    if (id) {
      setLoading(true);
      customersAPI
        .getCustomerById({ id })
        .then((res) => {
          setCustomer(res.data.data);
        })
        .catch(() => {
          navigate('/404');
        })
        .finally(() => setLoading(false));
    } else {
      navigate('/404');
    }
  }, [id, setLoading, customersAPI, setCustomer, navigate]);

  useEffect(() => {
    fetchCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper>
      {loading && <LoadingSpinner />}
      {!loading && customer && (
        <TabStatusBarLayout
          breadcrumb={{
            title: 'Einrichtungen Übersicht',
            href: '/customers',
          }}
          subPageBreadcrumb={{
            title: customer.displayName,
            href: `/customers/${customer.id}`,
          }}
          header={<CustomerDetailsStatusCard customer={customer} />}
          items={[
            {
              title: 'Informationen',
              content: <CustomerDetailsInformation customer={customer} />,
              hash: '',
            },
            {
              title: 'Kontakt',
              content: <CustomerDetailsContacts customer={customer} updateHook={fetchCustomer} />,
              hash: '#contact',
            },
            {
              title: 'Fahrten',
              content: <ToursByDayForWeek customerId={customer.id} />,
              hash: '#tours',
            },
            {
              title: 'Aufträge',
              content: <CustomerDetailsOrders customerId={customer.id} updateHook={fetchCustomer} />,
              hash: '#orders',
            },
            {
              title: 'Fahrgäste',
              content: <CustomerDetailsPassengers customerId={customer.id} updateHook={fetchCustomer} />,
              hash: '#passengers',
            },
            {
              title: 'Abrechnung',
              content: <CustomerDetailsAccounting customerId={customer.id} />,
              hash: '#accounting',
            },
            // {
            //   title: 'Schließzeiten',
            //   content: <CustomerDetailsExceptions customerId={customer.id} />,
            //   hash: '#exceptions',
            // },
          ]}
        />
      )}
    </Wrapper>
  );
};
