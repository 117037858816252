import { FC } from 'react';
import AnchorSectionView from '../AnchorSectionView';
import EmployeeInvoicesTimeSupplements from './EmployeeInvoicesTimeSupplements';
import { EmployeeResponseItem } from 'json-schemas/Employee.ResponseItem';

export const EmployeeDetailsDocuments: FC<{ employee: EmployeeResponseItem }> = (props) => {
  return (
    <AnchorSectionView
      sectionHeadline={'Abrechnung'}
      items={[
        {
          title: 'Zeitzuschläge',
          content: <EmployeeInvoicesTimeSupplements employee={props.employee} />,
        },
      ]}
    />
  );
};
