import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { getCurrentDayDate, getDayOfWeekDate } from 'utils/dateUtils';
import { DateWeekOfYearInput } from 'components/common/inputs/DateWeekOfYearInput';
import { LiveIframe } from 'components/LiveIframe';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 var(--content-indent);
  margin-inline: calc(-1 * var(--content-indent));
`;

const StyledWeekOfYearInput = styled(DateWeekOfYearInput)`
  align-self: flex-start;
  z-index: 2;
`;

interface PassengerDetailsToursProps {
  passengerId?: string;
  customerId?: string;
  employeeId?: string;
}

export const ToursByDayForWeek: FC<PassengerDetailsToursProps> = (props) => {
  const { passengerId, customerId, employeeId } = props;
  const [weekOfYear, setWeekOfYear] = useState(getCurrentDayDate(getDayOfWeekDate(1)).toISOString());

  const endOfWeek = useMemo(() => {
    return getDayOfWeekDate(7, weekOfYear).toISOString();
  }, [weekOfYear]);

  const liveQueryParams = useMemo(() => {
    let result: {
      start_date_lts: string,
      end_date_lts: string,
      passenger_id?: string,
      customer_id?: string,
      employee_id?: string
    } = {
      start_date_lts: weekOfYear,
      end_date_lts: endOfWeek
    };

    if (passengerId) {
      result.passenger_id = passengerId
    }

    if (customerId) {
      result.customer_id = customerId
    }

    if (employeeId) {
      result.employee_id = employeeId
    }

    return result
  }, [weekOfYear, endOfWeek])

  return (
    <Wrapper>
      <StyledWeekOfYearInput value={weekOfYear} setValue={setWeekOfYear} />
      <LiveIframe
        styleKind="mainSection"
        path={`/tours_for_day`}
        queryParams={liveQueryParams}
      />
    </Wrapper>
  );
};
