import styled, { css } from 'styled-components';
import { FC, useEffect, useMemo, useState } from 'react';
import { Colors } from 'styles/Colors';
import { BREAKPOINT_MD, DIMEN_BREAKPOINT_MD } from 'styles/Breakpoints';
import { Plus } from '../icons/Plus';
import { Tours } from '../icons/Tours';
import { MenuHide } from '../icons/MenuHide';
import { ButtonPrimary, ButtonSecondary } from '../common/inputs/Button';
import { Clickable } from '../common/atoms/Clickable';
import { ProfileMenu } from '../common/elements/ProfileMenu';
import { INavItem, NavItem } from '../common/atoms/NavItem';
import { NewTourDialog } from '../common/elements/tours/NewTourDialog';
import { useAuthAPI } from 'api/controllers/AuthAPI';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UserMeState } from 'state/UserMeState';
import { createPortal } from 'react-dom';
import { Minus } from '../icons/Minus';
import BulkCancellationDialog from '../common/elements/tours/BulkCancellationDialog';
import { Clock } from '../icons/Clock';
import { NavigationLogo } from '../icons/NavigationLogo';
import Settings from '../icons/Settings';
import { Passengers } from '../icons/Passengers';
import { Employee } from '../icons/Employee';
import { Facilities } from '../icons/Facilities';
import { TourListSettingState } from 'state/TourListSettingState';
import { getCurrentDayDate, getDayOfWeekDate, getISODate } from 'utils/dateUtils';

const Wrapper = styled.nav<{ collapsed: boolean }>`
  z-index: 6;
  flex-shrink: 0;

  ${BREAKPOINT_MD} {
    inline-size: ${({ collapsed }) => (collapsed ? '5.5rem' : '20rem')};
    transition: inline-size var(--animation-speed) ease-out;
  }
`;

const IconWrapper = styled.div<{ collapsed: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: ${({ collapsed }) => (collapsed ? '-1.5rem' : '0')};
  margin-top: ${({ collapsed }) => (collapsed ? '-2rem' : '0')};

  svg {
    width: 90%;
    height: auto;
  }
`;

const Drawer = styled.div<{ collapsed: boolean; navCollapsed: boolean }>`
  position: fixed;
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  background: ${Colors.white50};
  padding-block: 1rem 3rem;
  padding-inline: 1rem;
  right: ${({ navCollapsed }) => (!navCollapsed ? '0' : '-100%')};
  transition: right var(--animation-speed) ease-out;

  ${BREAKPOINT_MD} {
    left: 0;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
    padding-block: 1rem;
    transform: none;
    inline-size: ${({ collapsed }) => (collapsed ? '5.5rem' : '20rem')};
    transition: inline-size var(--animation-speed) ease-out;
  }
`;

const DrawerHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  block-size: 3rem;
  flex-shrink: 0;
  margin-block-end: 0.5rem;

  ${BREAKPOINT_MD} {
    display: none;
  }
`;

const MenuHideIcon = styled(MenuHide)`
  align-self: flex-end;
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

const NavItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding-block: 0.25rem;
  margin-inline: -1rem;
  margin-block-end: auto;
  overflow-x: hidden;
  overflow-y: auto;
`;

const CTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-block: 0.5rem 2rem;

  button {
    max-width: none;
  }

  ${BREAKPOINT_MD} {
    align-items: stretch;
  }
`;

const ButtonStyles = css<{ collapsed: boolean }>`
  width: 15rem;

  ${BREAKPOINT_MD} {
    width: auto;
  }

  min-width: ${({ collapsed }) => (collapsed ? '3.125rem' : '15rem')};
  padding: ${({ collapsed }) => (collapsed ? '0' : '0 1.875rem')};
  transition: min-width var(--animation-speed) ease, padding var(--animation-speed) ease;

  svg {
    margin-right: ${({ collapsed }) => (collapsed ? '0' : '.5rem')};
    transition: margin-right var(--animation-speed) ease;
  }

  span {
    position: ${({ collapsed }) => (collapsed ? 'absolute' : 'relative')};
    opacity: ${({ collapsed }) => (collapsed ? '0' : '1')};
    transition: ${({ collapsed }) => (collapsed ? 'none' : 'opacity calc(var(--animation-speed) / 2) linear')};
    transition-delay: ${({ collapsed }) => (collapsed ? '0' : 'calc(var(--animation-speed) / 2)')};
  }
`;

const NavButtonPrimary = styled(ButtonPrimary)<{ collapsed: boolean }>`
  ${ButtonStyles};
`;

const NavButtonSecondary = styled(ButtonSecondary)<{ collapsed: boolean }>`
  ${ButtonStyles};
`;

const StyledClickable = styled(Clickable)<{ collapsed: boolean }>`
  display: none;

  ${BREAKPOINT_MD} {
    display: flex;
    justify-content: ${({ collapsed }) => (collapsed ? 'center' : 'flex-end')};
    padding-block: ${({ collapsed }) => (collapsed ? '1rem' : '0 1rem')};
  }
`;

interface NavigationProps {
  navCollapsed: boolean;
  setNavCollapsed: (value: boolean) => void;
}

export const Navigation: FC<NavigationProps> = (props) => {
  const { navCollapsed, setNavCollapsed } = props;
  const [openNewTourDialog, setOpenNewTourDialog] = useState<boolean>(false);
  const [openNewCancelDialog, setOpenCancelToursDialog] = useState<boolean>(false);

  const [toursSettings, setToursSettings] = useRecoilState(TourListSettingState);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(min-width: ${DIMEN_BREAKPOINT_MD}px)`);
    if (mediaQuery.matches) {
      document.body.classList.toggle('no-scroll', false);
    } else {
      document.body.classList.toggle('no-scroll', !navCollapsed);
    }
  }, [navCollapsed]);

  const { logout } = useAuthAPI();
  const userMe = useRecoilValue(UserMeState);

  // check user permissions and setup available nav items
  const navItems = useMemo(() => {
    const items: INavItem[] = [
      {
        title: 'Fahrten',
        href: '/tours',
        icon: (active: boolean) => <Tours bold={active} />,
        onClick: (active: boolean) => {
          if (active) {
            setToursSettings({
              ...toursSettings,
              start: getISODate(getDayOfWeekDate(1, getCurrentDayDate())),
              end: getISODate(getDayOfWeekDate(7, getCurrentDayDate())),
            });
          }
        },
        permissions: [],
      },
      {
        title: 'Fahrgäste',
        href: '/passengers',
        icon: (active: boolean) => <Passengers bold={active} />,
        permissions: [],
      },
      {
        title: 'Mitarbeiter',
        href: '/employees',
        icon: (active: boolean) => <Employee bold={active} />,
        permissions: [],
      },
      {
        title: 'Einrichtungen',
        href: '/customers',
        icon: (active: boolean) => <Facilities bold={active} />,
        permissions: [],
      },
      {
        title: 'Schließzeiten',
        href: '/calendar-exceptions',
        icon: (active: boolean) => <Clock bold={active} />,
        permissions: [],
      },
      {
        title: 'Stammdaten',
        href: '/stammdaten',
        icon: (active: boolean) => <Settings bold={active} />,
        permissions: [],
      },
    ].filter(
      ({ permissions }) =>
        permissions.length === 0 ||
        permissions.every(
          (permission) => userMe?.permissions?.split(';').includes('admin') || userMe?.permissions?.split(';').includes(permission),
        ),
    ) as INavItem[];

    return (
      <NavItemsWrapper>
        {items.map((item, index) => (
          <NavItem key={index} {...item} collapsed={navCollapsed} />
        ))}
      </NavItemsWrapper>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMe, navCollapsed]);

  const profileMenu = useMemo(
    () => <ProfileMenu collapsed={navCollapsed} userMe={userMe} logout={logout} />,
    [navCollapsed, userMe, logout],
  );

  const newTourDialog = useMemo(
    () => (
      <>
        {openNewTourDialog &&
          createPortal(
            <NewTourDialog
              onClosed={() => {
                setOpenNewTourDialog(false);
              }}
            />,
            document.querySelector('#portal') as Element,
          )}
      </>
    ),
    [openNewTourDialog, setOpenNewTourDialog],
  );

  const newCancelDialog = useMemo(
    () => (
      <>
        {openNewCancelDialog &&
          createPortal(
            <BulkCancellationDialog
              onClose={() => {
                setOpenCancelToursDialog(false);
              }}
            />,
            document.querySelector('#portal') as Element,
          )}
      </>
    ),
    [openNewCancelDialog, setOpenCancelToursDialog],
  );

  return (
    <Wrapper collapsed={navCollapsed}>
      <Drawer collapsed={navCollapsed} navCollapsed={navCollapsed}>
        <DrawerHead />

        <IconWrapper collapsed={navCollapsed}>
          <NavigationLogo viewBox={navCollapsed ? '0 0 36 59' : undefined} />
        </IconWrapper>

        <StyledClickable
          collapsed={navCollapsed}
          onClick={() => {
            setNavCollapsed(!navCollapsed);
          }}
        >
          <MenuHideIcon invert={navCollapsed} />
        </StyledClickable>

        {navItems}

        <CTAWrapper>
          <NavButtonSecondary
            collapsed={navCollapsed}
            onClick={() => {
              setOpenCancelToursDialog(true);
            }}
          >
            <Minus />
            <span>Fahrten absagen</span>
          </NavButtonSecondary>

          <NavButtonPrimary
            collapsed={navCollapsed}
            onClick={() => {
              setOpenNewTourDialog(true);
            }}
          >
            <Plus />
            <span>neue Fahrt planen</span>
          </NavButtonPrimary>
        </CTAWrapper>

        {profileMenu}

        {openNewTourDialog && newTourDialog}
        {openNewCancelDialog && newCancelDialog}
      </Drawer>
    </Wrapper>
  );
};
