import { Bridge, Empty } from '../bridge'

import type { EmployeeResponseItem } from 'json-schemas/Employee.ResponseItem';
import type { EmployeeReturningResponse } from 'json-schemas/Employee.ReturningResponse';
import type { EmployeeCastParams } from 'json-schemas/Employee.CastParams';
import type { EmployeeCreateUpdateResponse } from 'json-schemas/Employee.CreateUpdateResponse';

export class EmployeesBridge extends Bridge {
  async getById(id: string | number) {
    this.checkAuth();

    const result = await this.get<Empty, EmployeeResponseItem>(`/api/v2/employees/${id}`, {});

    return result;
  }

  async update(id: string, attrs: EmployeeCastParams) {
    this.checkAuth();

    const result = await this.patch<EmployeeCastParams, EmployeeCreateUpdateResponse>(`/api/v2/employees/${id}`, attrs);

    return result;
  }

  async getReturning(start_date: string, end_date: string) {
    this.checkAuth();

    const attrs = {start_date: start_date, end_date: end_date};
    const result = await this.get<typeof attrs, EmployeeReturningResponse>(`/api/v2/employees/returning`, attrs);

    return result;
  }
}
