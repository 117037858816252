import { useAuthHeader } from 'state/AuthState';
import axios from 'axios';

interface ICreatePassengerProps {
  //
}

interface IGetPassengersProps {
  offset: number;
  limit: number;
  ids?: string[];
  includeDrafts?: boolean;
}

interface IGetPassengerByIdProps {
  id: string;
}

interface IGetPassengerAbsencesProps {
  id: string;
  startDate: string;
  endDate?: string;
}

interface IUpdatePassengerProps extends ICreatePassengerProps {}

export const usePassengersAPI = () => {
  const authHeader = useAuthHeader();

  const checkAuth = async () => {
    if (!authHeader) {
      throw new Error('401');
    }
  };

  const createPassenger = (props: ICreatePassengerProps) => {
    return checkAuth().then(() => {
      return axios.post(`/api/passengers`, props, { ...authHeader });
    });
  };

  const getPassengers = (props: IGetPassengersProps) => {
    return checkAuth().then(() => {
      return axios.post(`/api/passengers/list`, props, { ...authHeader });
    });
  };

  const getPassengerById = (props: IGetPassengerByIdProps) => {
    return checkAuth().then(() => {
      return axios.get(`/api/passengers/${props.id}`, { ...authHeader });
    });
  };

  const updatePassenger = (id: string, props: IUpdatePassengerProps) => {
    return checkAuth().then(() => {
      return axios.patch(`/api/passengers/${id}`, props, { ...authHeader });
    });
  };

  const deletePassenger = (id: string) => {
    return checkAuth().then(() => {
      return axios.delete(`/api/passengers/${id}`, { ...authHeader });
    });
  };

  const restorePassenger = (id: string) => {
    return checkAuth().then(() => {
      return axios.post(`/api/passengers/${id}/restore`, {}, { ...authHeader });
    });
  };

  // NEW API ENDPOINTS
  const getPassengersForSelect = async ({
    customerId,
    organizationId,
    search,
  }: {
    customerId?: string;
    organizationId?: string;
    search?: string;
  }) => {
    return checkAuth().then(() => {
      return axios.post(`/api/passengers/for_picker`, { customerId, organizationId, search }, { ...authHeader });
    });
  };


  return {
    createPassenger,
    getPassengers,
    getPassengerById,
    updatePassenger,
    deletePassenger,
    restorePassenger,
    getPassengersForSelect
  };
};
