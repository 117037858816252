import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { EntityDetailsTours } from 'components/common/elements/tours/EntityDetailsTours';
import { getCurrentDayDate, getDayOfWeekDate, getISODate } from 'utils/dateUtils';
import { DateWeekOfYearInput } from 'components/common/inputs/DateWeekOfYearInput';
import { useTourConflicts } from 'state/TourConflictsState';
import ConflictBanners from 'components/common/elements/ConflictBanners';
import FilterDescriptionComponent from './FilterDescriptionComponent';
import { DnDScrollableContentWrapper } from 'components/common/elements/DnDScrollableContentWrapper';
import ScheduleColumn from './ScheduleColumn';
import { ContentPageHead } from 'components/common/elements/ContentPageHead';
import { ToursListFilter } from 'components/content/tours/ToursListFilter';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UiState } from 'state/UiState';
import { TourListSettingState } from 'state/TourListSettingState';
import { DIMEN_BREAKPOINT_MD } from 'styles/Breakpoints';

import { useAuthHelp } from 'state/AuthState';
import type { EmployeeReturningResponse } from 'json-schemas/Employee.ReturningResponse';
import { EmployeesBridge } from 'bridges/EmployeesBridge';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
`;

const RowWrapper = styled.section`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 1rem;
  flex-grow: 1;
`;

const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 2rem;

  position: fixed;
  inset-inline: var(--offset-nav) 0;
  inset-block: var(--offset-top) 0;

  padding-inline: calc(var(--offset-left) - var(--offset-nav) + var(--content-indent)) var(--content-indent);

  overflow: auto; // horizontal scrolling
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding-inline: var(--content-indent);
  padding-block: 0 var(--content-indent);
  overflow: auto;
  overflow-x: hidden;
`;

export const ToursList = () => {
  const uiState = useRecoilValue(UiState);
  const [settings, setSettings] = useRecoilState(TourListSettingState);
  const [returningEmployees, setReturningEmployees] = useState<EmployeeReturningResponse>([]);
  const { data: conflicts } = useTourConflicts(new Date(settings.start).toISOString(), new Date(settings.end).toISOString());

  const authHelp = useAuthHelp();
  const employeesBridge = new EmployeesBridge(authHelp);

  useEffect(() => {
    employeesBridge.getReturning(settings.start, settings.end).then((response) => {
      setReturningEmployees(response);
    });
  }, [settings.start, settings.end]);

  const visibleDays = useMemo(() => {
    const current = getCurrentDayDate(settings.start);
    const days: string[] = [];
    while (current <= new Date(settings.end)) {
      days.push(current.toISOString());
      current.setDate(current.getDate() + 1);
    }
    return days;
  }, [settings.start, settings.end]);

  useEffect(() => {
    if (uiState.tourList.activeTab === 'tile') {
      const calendarTilesWrapper = document.querySelector('[data-calendar-tiles]') as HTMLDivElement;
      const currentDaySelector = `[data-day="${getCurrentDayDate().toISOString()}"]`;
      const currentDayView = document.querySelector(currentDaySelector) as HTMLDivElement;

      if (currentDayView) {
        const offset = window.innerWidth < DIMEN_BREAKPOINT_MD ? 0 : 48;
        calendarTilesWrapper?.scrollTo({
          left: currentDayView.offsetLeft - offset,
          behavior: 'auto',
        });
      }
    }
  }, [uiState.tourList.activeTab]);

  return (
    <Wrapper>
      <ContentPageHead title={'Fahrten'} listName="tourList">
        <RowWrapper>
          <DateWeekOfYearInput
            value={settings.start as unknown as string}
            setValue={(value) => {
              const startOfWeekDate = getISODate(getDayOfWeekDate(1, getCurrentDayDate(value)));
              const endOfWeekDate = getISODate(getDayOfWeekDate(7, startOfWeekDate));
              setSettings({ ...settings, start: startOfWeekDate, end: endOfWeekDate });
            }}
          />
          <ToursListFilter />
        </RowWrapper>
      </ContentPageHead>

      <ConflictBanners
        fetchedConflicts={conflicts}
        regions={settings.filters.regions}
        directions={settings.filters.directions}
        returningEmployees={returningEmployees}
      />

      <FilterDescriptionComponent />

      <DnDScrollableContentWrapper isDraggable={uiState.tourList.activeTab === 'tile'}>
        {uiState.tourList.activeTab === 'tile' ? (
          <CalendarWrapper data-calendar-tiles>
            {visibleDays.map((day) => (
              <ScheduleColumn key={day} day={day} conflicts={conflicts?.[day] || []} />
            ))}
          </CalendarWrapper>
        ) : (
          <ListWrapper data-calendar-list>
            <EntityDetailsTours hideWeekOfYearSelection={true} visibleDays={visibleDays} withPaginationPerDay={true} />
          </ListWrapper>
        )}
      </DnDScrollableContentWrapper>
    </Wrapper>
  );
};
