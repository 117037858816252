import { FC, PropsWithChildren, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Home } from 'components/pages/Home';
import { Page404 } from 'components/pages/Page404';
import { PageLayout } from 'components/layout/PageLayout';
import { Login } from 'components/pages/auth/Login';
import { useAuthToken } from 'state/AuthState';
import { ForgotPassword } from 'components/pages/auth/ForgotPassword';
import { ResetPassword } from 'components/pages/auth/ResetPassword';
import { Employees } from 'components/pages/employees/Employees';
import { Customers } from 'components/pages/customers/Customers';
import { EmployeeDetail } from 'components/pages/employees/EmployeeDetail';
import { EmployeeEdit } from 'components/pages/employees/EmployeeEdit';
import { CustomerDetail } from 'components/pages/customers/CustomerDetail';
import { CustomerEdit } from 'components/pages/customers/CustomerEdit';
import { CustomersAdd } from 'components/pages/customers/CustomersAdd';
import { CalendarExceptions } from 'components/pages/calendar-exceptions/CalendarExceptions';
import { CalendarExceptionDetail } from 'components/pages/calendar-exceptions/CalendarExceptionDetail';
import { CalendarExceptionEdit } from 'components/pages/calendar-exceptions/CalendarExceptionEdit';
import { CalendarExceptionAdd } from 'components/pages/calendar-exceptions/CalendarExceptionAdd';
import { CustomerCategories } from 'components/pages/customer-categories/CustomerCategories';
import { CustomerCategoryDetail } from 'components/pages/customer-categories/CustomerCategoryDetail';
import { CustomerCategoryEdit } from 'components/pages/customer-categories/CustomerCategoryEdit';
import { CustomerCategoryAdd } from 'components/pages/customer-categories/CustomerCategoryAdd';
import { CustomerOrganizationAdd } from 'components/pages/customer-organizations/CustomerOrganizationAdd';
import { CustomerOrganizationEdit } from 'components/pages/customer-organizations/CustomerOrganizationEdit';
import { Passengers } from 'components/pages/passengers/Passengers';
import { PassengerDetail } from 'components/pages/passengers/PassengerDetail';
import { PassengerEdit } from 'components/pages/passengers/PassengerEdit';
import { PassengersAdd } from 'components/pages/passengers/PassengersAdd';
import { TourDetail } from 'components/pages/tours/TourDetail';
import { Tours } from 'components/pages/tours/Tours';
import { CustomerOrdersAdd } from 'components/pages/customers/orders/CustomerOrdersAdd';
import { CustomerOrderEdit } from 'components/pages/customers/orders/CustomerOrderEdit';
import { CustomerTourplan } from 'components/pages/customers/CustomerTourplan';
import Accounting from './pages/accounting/Accounting';
import { CustomerDetailInvoiceDetails } from 'components/pages/customers/CustomerDetailInvoiceDetails';
import { UserManagement } from 'components/pages/user-management/UserManagement';
import { Icons } from 'components/icons/Icons';
import { BaseData } from 'components/pages/base-data/BaseData';
import { Finance } from 'components/pages/finance/Finance';
import { useQuery } from '@tanstack/react-query';
import { IUser } from 'api/models/User';
import { useRecoilState } from 'recoil';
import { useAuthAPI } from 'api/controllers/AuthAPI';
import { UserMeState } from 'state/UserMeState';
import { AxiosError } from 'axios';
import { UseFormTest } from './test/UseFormTest';

const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const authToken = useAuthToken();
  const { logout, getUserMe } = useAuthAPI();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_userMe, setUserMe] = useRecoilState(UserMeState);
  const location = useLocation();

  const {
    data: profile,
    error,
    refetch,
  } = useQuery({
    queryKey: ['userMe'],
    refetchInterval: 1000 * 60 * 5,
    retryDelay: 350,
    queryFn: async () => {
      const { data } = await getUserMe();

      return data as IUser;
    },
  });

  useEffect(() => {
    const axiosError = error as AxiosError;
    if (axiosError) {
      if (axiosError.response?.status !== 500) {
        console.log('error', error);
        setUserMe(null);
        logout();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (profile) {
      setUserMe(profile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (authToken) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, location.pathname]);

  if (authToken) {
    return <>{children}</>;
  }

  return <Navigate replace to={'/login'} />;
};

function App() {
  return (
    <Routes>
      <Route path={'/'} element={<PageLayout />}>
        {/* auth routes */}
        <Route path={'/login'} element={<Login />} />
        <Route path={'/forgot-password'} element={<ForgotPassword />} />
        <Route path={'/reset-password'} element={<ResetPassword />} />

        {/* pages */}
        <Route
          path={'/'}
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/employees/:id/edit'}
          element={
            <ProtectedRoute>
              <EmployeeEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/employees/:id'}
          element={
            <ProtectedRoute>
              <EmployeeDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/employees'}
          element={
            <ProtectedRoute>
              <Employees />
            </ProtectedRoute>
          }
        />

        <Route
          path={'/customers/:customerId/orders/add'}
          element={
            <ProtectedRoute>
              <CustomerOrdersAdd />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/customers/:customerId/orders/:id/edit'}
          element={
            <ProtectedRoute>
              <CustomerOrderEdit />
            </ProtectedRoute>
          }
        />

        <Route
          path={'/customers/add'}
          element={
            <ProtectedRoute>
              <CustomersAdd />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/customers/:id/edit'}
          element={
            <ProtectedRoute>
              <CustomerEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/customers/:id/tourplan'}
          element={
            <ProtectedRoute>
              <CustomerTourplan />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/customers/:customerId/invoice/:invoiceId'}
          element={
            <ProtectedRoute>
              <CustomerDetailInvoiceDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/customers/:id'}
          element={
            <ProtectedRoute>
              <CustomerDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/customers'}
          element={
            <ProtectedRoute>
              <Customers />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/customer-organizations/add'}
          element={
            <ProtectedRoute>
              <CustomerOrganizationAdd />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/customer-organizations/:id/edit'}
          element={
            <ProtectedRoute>
              <CustomerOrganizationEdit />
            </ProtectedRoute>
          }
        />

        <Route
          path={'/calendar-exceptions'}
          element={
            <ProtectedRoute>
              <CalendarExceptions />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/calendar-exceptions/add'}
          element={
            <ProtectedRoute>
              <CalendarExceptionAdd />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/calendar-exceptions/:id/edit'}
          element={
            <ProtectedRoute>
              <CalendarExceptionEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/calendar-exceptions/:id'}
          element={
            <ProtectedRoute>
              <CalendarExceptionDetail />
            </ProtectedRoute>
          }
        />

        <Route
          path={'/customer-categories'}
          element={
            <ProtectedRoute>
              <CustomerCategories />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/customer-categories/add'}
          element={
            <ProtectedRoute>
              <CustomerCategoryAdd />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/customer-categories/:id/edit'}
          element={
            <ProtectedRoute>
              <CustomerCategoryEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/customer-categories/:id'}
          element={
            <ProtectedRoute>
              <CustomerCategoryDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/calendar-exceptions'}
          element={
            <ProtectedRoute>
              <CustomerCategories />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/tours/:id'}
          element={
            <ProtectedRoute>
              <TourDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/tours'}
          element={
            <ProtectedRoute>
              <Tours />
            </ProtectedRoute>
          }
        />

        <Route
          path={'/passengers/add'}
          element={
            <ProtectedRoute>
              <PassengersAdd />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/passengers/:id/edit'}
          element={
            <ProtectedRoute>
              <PassengerEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/passengers/:id'}
          element={
            <ProtectedRoute>
              <PassengerDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/passengers'}
          element={
            <ProtectedRoute>
              <Passengers />
            </ProtectedRoute>
          }
        />

        <Route
          path={'/accounting'}
          element={
            <ProtectedRoute>
              <Accounting />
            </ProtectedRoute>
          }
        />

        <Route
          path={'/stammdaten'}
          element={
            <ProtectedRoute>
              <BaseData />
            </ProtectedRoute>
          }
        />

        <Route
          path={'/finanzen'}
          element={
            <ProtectedRoute>
              <Finance />
            </ProtectedRoute>
          }
        />

        <Route
          path={'/user-management'}
          element={
            <ProtectedRoute>
              <UserManagement />
            </ProtectedRoute>
          }
        />


        {process.env.NODE_ENV === 'development' && <Route path={'/form-test'} element={<UseFormTest />} />}
        {process.env.NODE_ENV === 'development' && <Route path={'/icons'} element={<Icons />} />}
        <Route path={'/404'} element={<Page404 />} />

        {/* fallback route */}
        <Route path={'/*'} element={<Navigate to={'/404'} />} />
      </Route>
    </Routes>
  );
}

export default App;
