// General
import { FC, useMemo } from 'react';
import styled from 'styled-components';


// Utils
import { PassengerAbsenceRow } from 'components/common/elements/passenger/PassengerAbsenceRow';
import { getCurrentDayDate } from 'utils/dateUtils';
import { Colors } from 'styles/Colors';
import { PassengerAbsenceResponseItem } from 'json-schemas/PassengerAbsence.ResponseItem';

const AccordionContent = styled.div`
  background: ${Colors.white50};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 1.5rem;
  display: grid;
  margin-block-start: 1.5rem;

  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1rem 10rem;
  padding-inline: 1rem;
  padding-block: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const AccordionColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex: 1;
`;

interface PassengerAbsenceProps {
  id: string;
  items: PassengerAbsenceResponseItem[];
  handleOpenDialog: (value?: PassengerAbsenceResponseItem) => void;
}

const PassengerAbsence: FC<PassengerAbsenceProps> = (props) => {
  const { items, id, handleOpenDialog } = props;

  const pastAbsences = useMemo(() => {
    return items.filter((item) => new Date(item.start_date) <= getCurrentDayDate());
  }, [items]);

  const futureAbsences = useMemo(() => {
    return items.filter((item) => new Date(item.start_date) > getCurrentDayDate());
  }, [items]);

  return (
    <AccordionContent>
      <AccordionColumn>
        Vergangen/Aktuell
        {pastAbsences.map((item, index) => (
          <PassengerAbsenceRow key={index} id={id} item={item} onClick={handleOpenDialog} />
        ))}
      </AccordionColumn>

      <AccordionColumn>
        Bald
        {futureAbsences.map((item, index) => (
          <PassengerAbsenceRow key={index} id={id} item={item} onClick={handleOpenDialog} />
        ))}
      </AccordionColumn>
    </AccordionContent>
  );
};

export default PassengerAbsence;
