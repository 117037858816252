import { Card } from 'components/common/atoms/Card';
import styled from 'styled-components';
import { Colors } from 'styles/Colors';
import HoverTooltip from 'components/common/elements/HoverTooltip';
import { InfoInverted } from 'components/icons/Info';
import { Body3 } from 'styles/FontStyles';
import TimeSupplementInput from 'components/common/inputs/TimeSupplementInput';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { getFirstDayOfMonth, getISODate, getLastDayOfMonth } from 'utils/dateUtils';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { useEmployeesAPI } from 'api/controllers/EmployeesAPI';
import { ButtonPrimary, ButtonSecondary } from 'components/common/inputs/Button';
import { BREAKPOINT_SM } from 'styles/Breakpoints';
import { DateMonthInput } from 'components/common/inputs/DateMonthInput';
import { useQuery } from '@tanstack/react-query';
import { useLatestBookingCompletion } from 'state/LatestBookingCompletionState';
import { EmployeeResponseItem } from 'json-schemas/Employee.ResponseItem';

const StyledCard = styled(Card)`
  gap: 1.5rem;
`;

interface IListProps {
  disabled: boolean;
}
const List = styled.div.attrs<IListProps>(() => ({}))<IListProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  padding-inline: 1rem;
  padding-block: 0.75rem;

  ${Body3};

  background-color: ${Colors.white50};
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  border-radius: 24px;

  & > :first-child {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const InfoIcon = styled(InfoInverted)`
  color: ${Colors.grey400};
`;

const ItemTimeSupplementInput = styled(TimeSupplementInput)`
  margin-inline-start: auto;
  inline-size: max-content;
  max-inline-size: none;
  flex-direction: row;

  ${Body3};
  color: ${Colors.textDefault};

  button {
    flex-shrink: 0;
    width: 3.5rem;
    border-radius: 8px;
    height: 1.5rem;
    color: ${Colors.secondary};
    padding: 1rem 0.5rem;
    border-color: transparent;
    background-color: ${Colors.secondaryLight};

    svg {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  flex-wrap: wrap;

  ${BREAKPOINT_SM} {
    justify-content: space-between;
  }
`;

const StyledMonthOfYearInput = styled(DateMonthInput)`
  align-self: flex-start;
  z-index: 2;
`;

const TimeInfo = styled.span`
  text-align: center;
`;

interface ITimeSupplementItemProps {
  value: number;
  setValue: (value: number) => void;
  tooltip?: string;
  type: 'minutes' | 'days';
}
const TimeSupplementItem = (props: PropsWithChildren<ITimeSupplementItemProps>) => {
  const { value, setValue, tooltip, children, type } = props;

  const steps =
    type === 'minutes'
      ? [
          { value: 5, label: '5' },
          { value: 15, label: '15' },
        ]
      : [{ value: 1440, label: '1' }]; // right now this is automatically days

  const formatValue = (value: number) => {
    if (type === 'minutes') {
      const hours: number = Math.floor(value / 60);
      const remainingMinutes: number = value % 60;
      const formattedMinutes: string = remainingMinutes.toString().padStart(2, '0');
      return `${hours}h ${formattedMinutes}min`;
    } else {
      const days = value / 1440;
      return `${days} ${days === 1 ? 'Tag' : 'Tage'}`;
    }
  };

  return (
    <ListItem>
      <span>{children}</span>
      {tooltip && (
        <HoverTooltip renderTooltipContent={() => <>{tooltip}</>}>
          <InfoIcon />
        </HoverTooltip>
      )}

      <ItemTimeSupplementInput type={type} value={value} setValue={setValue} steps={steps} withLabel={true} formatValue={formatValue} />
    </ListItem>
  );
};

export const TIME_SUPPLEMENTS = [
  {
    title: 'Werkstattbesuch',
    key: 'car_service',
    tooltip: 'Werkstatt',
    type: 'minutes',
  },
  {
    title: 'Sonderfahrt',
    key: 'sofa',
    type: 'minutes',
  },
  {
    title: 'Büro',
    key: 'office',
    type: 'minutes',
  },
  {
    title: 'Mehraufwand',
    key: 'extra_effort',
    type: 'minutes',
  },
  {
    title: 'Unterweisung',
    key: 'instruction',
    type: 'minutes',
  },
  {
    title: 'Einarbeitung',
    key: 'training',
    type: 'minutes',
  },
  {
    title: 'Fahrzeugpflege',
    key: 'car_cleaning',
    type: 'minutes',
  },
  {
    title: 'Notfallrückbeförderung',
    key: 'emergency_transport',
    type: 'minutes',
  },
  {
    title: 'Auszahlung Urlaubstage',
    key: 'payout_vacation_days',
    type: 'days',
  },
] as { title: string; key: string; type: 'minutes' | 'days'; tooltip?: string }[];

interface ITimeSupplementItem {
  id: string;
  createdAt: string;
  updatedAt: string;
  timestamp: string;
  amount: string;
  type: string;
  comment: string | null;
  employeeId: string;
  deletedAt: string | null;
}
interface ITimeSupplementItemResponse {
  count: number;
  rows: ITimeSupplementItem[];
}

export default function EmployeeInvoicesTimeSupplements(props: { employee: EmployeeResponseItem }) {
  const { id } = props.employee;

  const currentDate = new Date();
  const [startDate, setStartDate] = useState(getISODate(getFirstDayOfMonth(currentDate)));
  const [endDate, setEndDate] = useState(getISODate(getLastDayOfMonth(currentDate)));

  const latestBooking = useLatestBookingCompletion();
  const [latestBookingMonth, latestBookingDay] = useMemo(() => {
    if (latestBooking) {
      const givenDate = new Date(latestBooking.timestamp);
      return [givenDate.getMonth(), givenDate.getDate()];
    }
    return [];
  }, [latestBooking]);

  const [values, setValues] = useState<{ [key: string]: number }>({});
  const [changes, setChanges] = useState<{ [key: string]: number } | null>(null);

  const employeesAPI = useEmployeesAPI();
  const [submitting, setSubmitting] = useState(false);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['time-supplements', id, startDate, endDate],
    initialData: { rows: [], count: 0 },
    queryFn: async () => {
      const { data } = await employeesAPI.getEmployeeTimeSupplements({ id, startDate, endDate }).then((res) => res);

      return data as ITimeSupplementItemResponse;
    },
  });

  useEffect(() => {
    setValues(
      ((data?.rows || []) as any[]).reduce((res, timeSupplement) => {
        return {
          ...res,
          [timeSupplement.type]: (res[timeSupplement.type] || 0) + timeSupplement.amount,
        };
      }, {}),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <StyledCard>
      <StyledMonthOfYearInput
        value={startDate}
        setValue={(date) => {
          setStartDate(getISODate(getFirstDayOfMonth(new Date(date))));
          setEndDate(getISODate(getLastDayOfMonth(new Date(date))));
          setChanges(null);
        }}
      />
      {latestBookingMonth === new Date(startDate).getMonth() && <i>zuschläge gelten ab dem {latestBookingDay}. (letzte Abrechnung)</i>}

      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <List disabled={latestBooking ? new Date(latestBooking.timestamp) > new Date(endDate) : false}>
          {TIME_SUPPLEMENTS.map(({ title, key, type, tooltip }) => (
            <TimeSupplementItem
              key={key}
              type={type}
              tooltip={tooltip}
              value={(values[key] || 0) + (changes?.[key] || 0)}
              setValue={(value) => setChanges((state) => ({ ...state, [key]: value - (values[key] || 0) }))}
            >
              {title}
            </TimeSupplementItem>
          ))}
        </List>
      )}
      {changes && (
        <ButtonsWrapper>
          <ButtonSecondary onClick={() => setChanges(null)}>Verwerfen</ButtonSecondary>
          <TimeInfo>
            alle Zuschläge werden in Minuten gespeichert.
            <br /> 1 Tag = 1440 Minuten
          </TimeInfo>
          <ButtonPrimary
            onClick={() => {
              setSubmitting(true);
              Promise.all(
                Object.keys(changes).map((type) =>
                  employeesAPI.postEmployeeTimeSupplement(id, {
                    type,
                    timestamp: startDate,
                    amount: changes[type] || 0,
                    comment: null,
                  }),
                ),
              )
                .then(() => {
                  setChanges(null);
                })
                .catch(() => {})
                .finally(() => {
                  setSubmitting(false);
                  refetch();
                });
            }}
          >
            {submitting ? <LoadingSpinner /> : 'Änderungen speichern'}
          </ButtonPrimary>
        </ButtonsWrapper>
      )}
    </StyledCard>
  );
}
