import { Toast } from 'components/common/elements/Toast';

import { Fragment, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { IConflict } from 'api/models/Conflict';
import { ITourConflictsResult } from 'state/TourConflictsState';
import { TourListSettingState } from 'state/TourListSettingState';
import { Colors } from 'styles/Colors';
import { Clickable } from '../atoms/Clickable';
import { Link } from 'react-router-dom';
import type { EmployeeReturningResponse } from 'json-schemas/Employee.ReturningResponse';

const ConflictItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  span {
    display: flex;
    flex-shrink: 0;
    padding-inline-end: 0.25rem;
  }
`;

const ClickableConflictItem = styled(Clickable)`
  display: flex;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    inset-inline: 0;
    inset-block-end: 0;
    inline-size: 100%;
    block-size: 2px;
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 100ms ease-out;
  }

  @media (hover: hover) {
    &:hover {
      color: ${Colors.grey800};

      &::after {
        transform: scaleX(1);
      }
    }
  }
`;

export default function ConflictBanners({
  fetchedConflicts,
  regions,
  directions,
  returningEmployees = [],
}: {
  fetchedConflicts?: ITourConflictsResult;
  regions: string[];
  directions: string[];
  returningEmployees?: EmployeeReturningResponse;
}) {
  const conflicts = useMemo(() => {
    if (fetchedConflicts) {
      return Object.values(fetchedConflicts)
        ?.map((dayConflicts: any) => dayConflicts)
        .flat() as IConflict[];
    }
  }, [fetchedConflicts]);

  const [settings, setSettings] = useRecoilState(TourListSettingState);

  const [conflictsGeneric, conflictsDriverInfo] = useMemo(() => {
    const conflictsGeneric = [] as IConflict[];
    const conflictsDriverInfo = [] as IConflict[];

    conflicts?.forEach((conflict) => {
      let visible = true;

      // property filters
      if (!!regions.length && !regions.includes(conflict.tour.customer?.region || '')) {
        visible = false;
      }
      if (!!directions.length && !directions.includes(conflict.tour.direction || '')) {
        visible = false;
      }

      if (visible) {
        if (['driver_duplicated', 'companion_duplicated'].includes(conflict.reason)) {
          conflictsDriverInfo.push(conflict);
        } else {
          conflictsGeneric.push(conflict);
        }
      }
    });

    return [conflictsGeneric, conflictsDriverInfo] as const;
  }, [conflicts, regions, directions]);

  return (
    <>
      {conflictsGeneric.length > 0 && !settings.showConflicts.generic && (
        <Toast
          type={'error'}
          style={{ marginBlockEnd: '1.5rem' }}
          onClick={() =>
            setSettings({ ...settings, showConflicts: { ...settings.showConflicts, generic: !settings.showConflicts.generic } })
          }
        >
          Es gibt Konflikte bei folgenden Touren:
          <ConflictItemsWrapper>
            {conflictsGeneric
              .map(({ tour }) => ({
                search: tour.name,
                title: `${tour.name} (${tour.direction === 'return' ? 'Rück' : 'Hin'})`,
              }))
              .filter((item, index, array) => array.findIndex(({ search }) => search === item.search) === index)
              .map(({ search, title }, index) => (
                <Fragment key={index}>
                  {index > 0 && <span>, </span>}
                  <ClickableConflictItem
                    onClick={() => {
                      setSettings({ ...settings, query: search });
                    }}
                  >
                    {title}
                  </ClickableConflictItem>
                </Fragment>
              ))}
          </ConflictItemsWrapper>
        </Toast>
      )}
      {conflictsDriverInfo.length > 0 && !settings.showConflicts.driverInfo && (
        <Toast
          type={'error'}
          style={{ marginBlockEnd: '1.5rem' }}
          onClick={() =>
            setSettings({ ...settings, showConflicts: { ...settings.showConflicts, driverInfo: !settings.showConflicts.driverInfo } })
          }
        >
          Folgende Fahrer sind mehrfach geplant:
          <ConflictItemsWrapper>
            {conflictsDriverInfo
              .map(({ tour }) => tour.driver?.fullNameWithComma)
              .filter((item, index, array) => array.indexOf(item) === index)
              .map((name, index) => (
                <Fragment key={index}>
                  {index > 0 && <span>; </span>}
                  <ClickableConflictItem onClick={() => setSettings({ ...settings, query: name || '' })}>{name}</ClickableConflictItem>
                </Fragment>
              ))}
          </ConflictItemsWrapper>
        </Toast>
      )}
      {returningEmployees.length > 0 && !settings.showConflicts.driverInfo && (
        <Toast
          type={'info'}
          style={{ marginBlockEnd: '1.5rem' }}
          onClick={() =>
            setSettings({ ...settings, showConflicts: { ...settings.showConflicts, driverInfo: !settings.showConflicts.driverInfo } })
          }
        >
          Folgende Fahrer kehren innerhalb des Planungszeitraums oder in der nächsten Woche zurück:
          <ConflictItemsWrapper>
            {returningEmployees.map((absence, index) => (
              <Fragment key={index}>
                {index > 0 && <span>; </span>}
                <ClickableConflictItem onClick={() => setSettings({ ...settings, query: absence.employee.name || '' })}>
                  {absence.employee.name},{' '}
                </ClickableConflictItem>
                <Link to={`/employees/${absence.employee?.id}#absences`}>
                  &nbsp;({new Date(absence.employeeAbsence.endDate).toLocaleDateString()})
                </Link>
              </Fragment>
            ))}
          </ConflictItemsWrapper>
        </Toast>
      )}
    </>
  );
}
