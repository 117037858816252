// General
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuthHelp } from 'state/AuthState';

// Components
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import { EmployeeDetailsStatusCard } from 'components/common/elements/employee/EmployeeDetailsStatusCard';
import { TabStatusBarLayout } from 'components/common/elements/TabStatusBarLayout';
import { EmployeeDetailsInformation } from 'components/common/elements/employee/EmployeeDetailsInformation';
import { ToursByDayForWeek } from 'components/common/elements/ToursByDayForWeek';
import { EmployeeDetailsAbsences } from 'components/common/elements/employee/EmployeeDetailsAbsences';
import { EmployeeDetailsDocuments } from 'components/common/elements/employee/EmployeeDetailsDocuments';

// Bridge
import { EmployeesBridge } from 'bridges/EmployeesBridge'

// Types
import { formatName } from 'utils/nameUtils';
import { EmployeeResponseItem } from 'json-schemas/Employee.ResponseItem';
import { IEmployeeAbsence } from 'api/models/EmployeeAbsence';
import { getCurrentDayDate } from 'utils/dateUtils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;
`;

export const EmployeeDetails: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const authHelp = useAuthHelp();
  const employeesBridge = new EmployeesBridge(authHelp);
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState<EmployeeResponseItem | null>(null);
  const [,setEmployeeAbsences] = useState<IEmployeeAbsence[]>([]);

  useEffect(() => {
    if (id) {
      setLoading(true);

      employeesBridge.getById(id)
        .then((data) => {
          setEmployee(data);
        })
        .catch(() => {
          navigate('/404');
        })
        .finally(() => setLoading(false));
    } else {
      navigate('/404');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const isAbsent = employee?.absences?.some(({ startDate, endDate }) => {
    const date = getCurrentDayDate().getTime();
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();

    return start <= date && end >= date;
  })

  return (
    <Wrapper>
      {loading && <LoadingSpinner />}

      {!loading && employee && (
        <TabStatusBarLayout
          breadcrumb={{
            title: 'Mitarbeiter Übersicht',
            href: '/employees',
          }}
          subPageBreadcrumb={{
            title: formatName(employee)!,
            href: `/employees/${employee.id}`,
          }}
          header={<EmployeeDetailsStatusCard employee={employee} isAbsent={isAbsent} />}
          items={[
            {
              title: 'Informationen',
              content: <EmployeeDetailsInformation employee={employee} />,
              hash: '',
            },
            {
              title: 'Fahrten',
              content: <ToursByDayForWeek employeeId={employee.id} />,
              hash: '#tours',
            },
            {
              title: 'Abwesenheiten',
              content: (
                <EmployeeDetailsAbsences
                  employee={employee}
                  onAbsencesChange={(absences) => {
                    setEmployeeAbsences((state) => {
                      if (state) {
                        const currentAbsences = (absences || [])
                          .map(({ id }) => id)
                          .sort()
                          .join(',');
                        const updatedAbsences = absences
                          .map(({ id }) => id)
                          .sort()
                          .join(',');
                        if (currentAbsences !== updatedAbsences) {
                          return {
                            ...state,
                            absences,
                          };
                        }
                      }
                      return state;
                    });
                  }}
                />
              ),
              hash: '#absences',
            },
            {
              title: 'Abrechnung',
              content: <EmployeeDetailsDocuments employee={employee} />,
              hash: '#accounting',
            },
          ]}
        />
      )}
    </Wrapper>
  );
};
