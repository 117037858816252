import { FC, memo, useDeferredValue, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSchedulesAPI } from 'api/controllers/SchedulesAPI';
import { IScheduledTour } from 'api/models/ScheduledTour';
import { useMatchBreakpoint } from 'hooks/useMatchBreakpoint';
import { useTourConflicts } from 'state/TourConflictsState';
import { BREAKPOINT_LG, BREAKPOINT_XL, BREAKPOINT_XXL } from 'styles/Breakpoints';
import { Colors } from 'styles/Colors';
import { getCurrentDayDate, getDayOfWeekDate } from 'utils/dateUtils';
import ScheduleGroupedByDayList from 'components/content/tours/ScheduleGroupedByDayList';
import { ChevronDown } from 'components/icons/ChevronDown';
import { Clickable } from 'components/common/atoms/Clickable';
import { List, ListHead } from 'components/common/atoms/List';
import { DateWeekOfYearInput } from 'components/common/inputs/DateWeekOfYearInput';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import ToursForDay from './ToursForDay';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 var(--content-indent);
  margin-inline: calc(-1 * var(--content-indent));
`;

const StyledList = styled(List)`
  margin-block-start: 2.5rem;
  --list-template: 1fr;

  ${BREAKPOINT_XL} {
    --list-template: 1fr 1fr 1fr 1fr 6.25rem 5rem 2rem;
  }
`;

const StyledListHead = styled(ListHead)<{ $stickyTop?: boolean }>`
  ${({ $stickyTop = false }) =>
    $stickyTop &&
    css`
      position: sticky;
      top: 0;
      z-index: 1;

      margin-block-start: -4.5rem;
      padding-block-start: 4.5rem;
    `};
`;

const StyledWeekOfYearInput = styled(DateWeekOfYearInput)`
  align-self: flex-start;
  z-index: 2;
`;

const StickyButtonWrapper = styled.div<{ $visible: boolean }>`
  position: sticky;
  bottom: 0.5rem;
  z-index: 1;
  margin-inline-start: auto;
  margin-inline-end: -1rem;

  transition: transform 150ms ease-out, opacity 150ms ease-out;
  transform: ${({ $visible }) => ($visible ? 'none' : 'translateX(100%)')};
  opacity: ${({ $visible }) => ($visible ? '1' : '0')};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-end;

  ${BREAKPOINT_LG} {
    bottom: 1rem;
    margin-inline-end: -4rem;
  }

  ${BREAKPOINT_XXL} {
    margin-inline-end: -5.5rem;
  }
`;

const StickyButton = styled(Clickable)`
  inline-size: 3rem;
  block-size: 3rem;
  border-radius: 50%;
  background-color: ${Colors.primary};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  svg {
    inline-size: 1.5rem;
    block-size: 1.5rem;
  }
`;

const ScrollTopButton = memo(() => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setVisible(document.body.scrollTop > 200);
    };

    document.body.addEventListener('scroll', onScroll);
    onScroll();

    return () => {
      document.body.removeEventListener('scroll', onScroll);
    };
  }, [setVisible]);

  const button = useMemo(
    () => (
      <StickyButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          document.body.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      >
        <ChevronDown invert />
      </StickyButton>
    ),
    [],
  );

  return <StickyButtonWrapper $visible={visible}>{button}</StickyButtonWrapper>;
});

interface ISchedule {
  day: string;
  schedules: IScheduledTour[];
}

interface IEntityDetailsToursProps {
  employeeId?: string | string[];
  customerId?: string;
  hideWeekOfYearSelection?: boolean;
  stickyTop?: boolean;
  withPaginationPerDay?: boolean; // only true for scheduled-tour view
  visibleDays?: string[];
  filter?: { [key: string]: any };
}

export const EntityDetailsTours: FC<IEntityDetailsToursProps> = (props) => {
  const {
    employeeId,
    customerId,
    hideWeekOfYearSelection = false,
    stickyTop = false,
    withPaginationPerDay = false,
    visibleDays = [],
  } = props;

  const schedulesAPI = useSchedulesAPI();
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState<ISchedule[]>([]);
  const [weekOfYear, setWeekOfYear] = useState(getCurrentDayDate(getDayOfWeekDate(1)).toISOString());

  const isDesktop = useMatchBreakpoint();

  const endOfWeek = useMemo(() => {
    return getDayOfWeekDate(7, weekOfYear).toISOString();
  }, [weekOfYear]);

  const syncedSearchParams = useDeferredValue(
    useMemo(
      () => ({
        withPaginationPerDay,
        weekOfYear,
        employeeId,
        customerId,
      }),
      [withPaginationPerDay, weekOfYear, employeeId, customerId],
    ),
  );

  useEffect(() => {
    const { withPaginationPerDay, weekOfYear, customerId, employeeId } = syncedSearchParams;

    if (!withPaginationPerDay) {
      setLoading(true);
      schedulesAPI
        .getSchedule({
          limit: -1,
          perDay: true,
          startDate: weekOfYear,
          endDate: endOfWeek,
          driverId: employeeId,
          customerId,
        })
        .then((res) => setSchedule(res.data))
        .catch(() => {})
        .finally(() => setLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncedSearchParams]);

  const { data: fetchedConflicts } = useTourConflicts(weekOfYear, endOfWeek);

  return (
    <Wrapper>
      {!hideWeekOfYearSelection && <StyledWeekOfYearInput value={weekOfYear} setValue={setWeekOfYear} />}

      {loading && <LoadingSpinner />}
      {(schedule.length > 0 || withPaginationPerDay) && (
        <StyledList>
          <StyledListHead $stickyTop={stickyTop}>
            {
              // mobile head
              isDesktop !== null && !isDesktop && (
                <div>
                  <b>Fahrten </b>
                  {[weekOfYear, endOfWeek]
                    .map((date) => getCurrentDayDate(date).toLocaleDateString(undefined, { dateStyle: 'medium' }))
                    .join(' bis ')}
                </div>
              )
            }
            {
              // desktop head
              isDesktop && (
                <>
                  <div>
                    <b>Tournummer</b>
                  </div>
                  <div>
                    <b>Einrichtung</b>
                  </div>
                  <div>Fahrer</div>
                  <div>Begleitung</div>
                  <div>Fahrzeit</div>
                  <div>Fahrgäste</div>
                </>
              )
            }
          </StyledListHead>
          {!withPaginationPerDay &&
            schedule.map(({ day, schedules }) => (
              <ToursForDay key={day} day={day} schedules={schedules} results={{ tourIds: [], conflicts: fetchedConflicts?.[day] }} />
            ))}
          {withPaginationPerDay &&
            visibleDays.map((day) => <ScheduleGroupedByDayList key={day} day={day} conflicts={fetchedConflicts?.[day]} />)}
          <ScrollTopButton />
        </StyledList>
      )}
    </Wrapper>
  );
};
