import styled from 'styled-components';
import { Body4, Body4Bold } from 'styles/FontStyles';
import { Colors } from 'styles/Colors';
import { FC } from 'react';
import { getAbsenceTypeLabel, IEmployeeAbsence } from 'api/models/EmployeeAbsence';
import { getCurrentDayDate } from 'utils/dateUtils';

export const ActiveLabel = styled.div<{ active?: boolean; state: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  height: 2rem;
  padding: 0.25rem 1rem;

  background: ${({ active = true }) => (active ? Colors.signalGreen800 : Colors.signalRed300)};
  border: 1px solid ${({ active = true }) => (active ? Colors.signalGreen900 : Colors.signalRed900)};
  border-radius: 22px;

  ${Body4};
  color: ${({ active = true }) => (active ? Colors.signalGreen900 : Colors.signalRed900)};

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &::before {
    content: '${({ state = '' }) => state}';
    display: ${({ state = '' }) => (!!state ? 'flex' : 'none')};
    ${Body4Bold};
  }
`;

export const EmployeeActiveLabel: FC<{ absences?: IEmployeeAbsence[]; className?: string }> = (props) => {
  const { className, absences = [] } = props;

  const activeAbsence = absences?.find(({ startDate, endDate }) => {
    const date = new Date().getTime();
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();

    return start <= date && end >= date;
  })

  return (
    <ActiveLabel
      active={absences.length === 0 || !activeAbsence}
      state={absences.length === 0 || !activeAbsence ? 'Aktiv' : getAbsenceTypeLabel(activeAbsence)}
      className={className}
    >
      {absences.length !== 0 &&
        activeAbsence &&
        `${!!activeAbsence.endDate ? 'bis' : 'seit'} ${new Date(activeAbsence.endDate || activeAbsence.startDate || '').toLocaleDateString('de', {
          dateStyle: 'medium',
        })}`}
    </ActiveLabel>
  );
};
