import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IPassenger } from 'api/models/Passenger';
import { ButtonSecondary } from 'components/common/inputs/Button';
import YearSelect from 'components/common/atoms/YearSelect';
import { PassengerAbsenceDialog } from 'components/common/elements/passenger/PassengerAbsenceDialog';
import { BREAKPOINT_MD } from 'styles/Breakpoints';
import { LoadingSpinner } from 'components/common/loaders/LoadingSpinner';
import PassengerAbsences from './PassengerAbsences';
import { useAuthHelp } from 'state/AuthState';
import { PassengerAbsencesBridge } from 'bridges/PassengerAbsencesBridge';
import { PassengerAbsenceResponseItem } from 'json-schemas/PassengerAbsence.ResponseItem';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-block-start: -0.5rem;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
`;

const SectionHeadline = styled.h3`
  font-size: 1.5rem;
  line-height: 2rem;

  ${BREAKPOINT_MD} {
    display: none;
  }
`;

const AddAbsenceButton = styled(ButtonSecondary)`
  //
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;
`;

export const PassengerDetailsAbsences: FC<{ passenger: IPassenger; onAbsencesChange: (absences: PassengerAbsenceResponseItem[]) => void }> = (
  props,
) => {
  const { passenger, onAbsencesChange } = props;
  const { id } = passenger;

  const [year, setYear] = useState<number>(new Date().getFullYear());
  const authHelp = useAuthHelp();
  const passengerAbsencesBridge = new PassengerAbsencesBridge(authHelp);
  const [absences, setAbsences] = useState<PassengerAbsenceResponseItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<PassengerAbsenceResponseItem | undefined>(undefined);

  const handleOpenDialog = (item?: PassengerAbsenceResponseItem) => {
    setCurrentItem(item);
    setOpenDialog(true);
  };

  const fetchAbsences = useCallback(() => {
    const startDate = new Date(year, 0, 1, 0, 0, 0, 0).toISOString();
    const endDate = new Date(year, 11, 31, 0, 0, 0, 0).toISOString();
    setLoading(true);
    passengerAbsencesBridge
      .getByPassenger(id, {startDate, endDate})
      .then((res) => {
        setAbsences(res.data);
        onAbsencesChange(res.data);
      })
      .catch((err) => {
        console.log('fetchAbsences error:', err);
      })
      .finally(() => setLoading(false));
  }, [id, passengerAbsencesBridge, year, onAbsencesChange]);

  useEffect(() => {
    fetchAbsences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  return (
    <Wrapper>
      <HeaderWrapper>
        <SectionHeadline>Abwesenheiten:</SectionHeadline>
        <YearSelect year={year} setYear={setYear} />
        <AddAbsenceButton onClick={() => handleOpenDialog()}>Abwesenheit eintragen</AddAbsenceButton>
      </HeaderWrapper>

      {loading && (
        <LoadingWrapper>
          <LoadingSpinner />
        </LoadingWrapper>
      )}

      {!loading && (
        <>
          <PassengerAbsences
            id={id}
            items={absences}
            handleOpenDialog={handleOpenDialog}
          />
        </>
      )}

      {openDialog && <PassengerAbsenceDialog id={id} item={currentItem} onSuccess={fetchAbsences} onClosed={() => setOpenDialog(false)} />}
    </Wrapper>
  );
};
