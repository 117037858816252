import { FC } from 'react';
import styled from 'styled-components';
import { Edit } from 'components/icons/Edit';
import { Trash } from 'components/icons/Trash';
import { ContextOptions } from '../ContextOptions';
import { useNavigate } from 'react-router-dom';
import { useShowDeleteEmployeeDialog } from 'hooks/employees/useShowDeleteEmployeeDialog';
import { useHasPermission } from 'state/UserMeState';
import { EmployeeResponseItem } from 'json-schemas/Employee.ResponseItem';

const EditIcon = styled(Edit)`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

const TrashIcon = styled(Trash)`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

export const EmployeeContextOptions: FC<{ employee: EmployeeResponseItem; onUpdate?: () => void }> = (props) => {
  const { employee, onUpdate } = props;

  const navigate = useNavigate();
  const showDeleteEmployeeDialog = useShowDeleteEmployeeDialog(employee.id);
  const isAdmin = useHasPermission('admin');

  return (
    <ContextOptions
      as="div"
      items={
        [
          isAdmin && {
            content: (
              <>
                <TrashIcon /> Entfernen
              </>
            ),
            onClick: () => showDeleteEmployeeDialog(onUpdate),
          },
          {
            content: (
              <>
                <EditIcon /> Bearbeiten
              </>
            ),
            onClick: () => {
              navigate(`/employees/${employee.id}/edit`);
              console.log('edit employee', employee);
            },
          },
        ].filter(Boolean) as []
      }
    />
  );
};
