import { Bridge, Empty } from '../bridge'

import type { PassengerAbsenceCollectionResponse } from 'json-schemas/PassengerAbsence.CollectionResponse';
import type { PassengerAbsenceItemResponse } from 'json-schemas/PassengerAbsence.ItemResponse';
import type { PassengerAbsenceCastParams } from 'json-schemas/PassengerAbsence.CastParams';

export class PassengerAbsencesBridge extends Bridge {
  async getByPassenger(id: string |number, params: { startDate: string, endDate: string}): Promise<PassengerAbsenceCollectionResponse> {
    this.checkAuth();

    const searchParams = new URLSearchParams(params);
    searchParams.set('startDate', params.startDate);
    searchParams.set('endDate', params.endDate);

    const urlParams = `?${searchParams.toString()}`;

    const result = await this.get<Empty, PassengerAbsenceCollectionResponse>(`/api/passengers/${id}/absences${urlParams}`, {});

    return result;
  }

  async getById(passengerId: string | number, id: string | number) {
    this.checkAuth();

    const result = await this.get<Empty, PassengerAbsenceItemResponse>(`/api/passengers/${passengerId}/absences/${id}`, {});

    return result;
  }

  async create(passengerId: string | number, attrs: PassengerAbsenceCastParams) {
    this.checkAuth();

    const result = await this.post<typeof attrs, PassengerAbsenceItemResponse>(`/api/passengers/${passengerId}/absences`, attrs);

    return result;
  }

  async updateWithId(passengerId: string | number, id: string | number, attrs: PassengerAbsenceCastParams) {
    this.checkAuth();

    const payload = { passenger_absence: attrs };
    const result = await this.patch<typeof payload, PassengerAbsenceItemResponse>(`/api/passengers/${passengerId}/absences/${id}`, payload);

    return result;
  }

  async deleteOne(passengerId: string | number, id: string | number) {
    this.checkAuth();

    return this.delete(`/api/passengers/${passengerId}/absences/${id}`);
  }
}
